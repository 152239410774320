// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agencies-index-jsx": () => import("./../../../src/pages/agencies/index.jsx" /* webpackChunkName: "component---src-pages-agencies-index-jsx" */),
  "component---src-pages-article-index-js": () => import("./../../../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-job-jsx": () => import("./../../../src/pages/job.jsx" /* webpackChunkName: "component---src-pages-job-jsx" */),
  "component---src-pages-magento-to-shopify-migration-jsx": () => import("./../../../src/pages/magento-to-shopify-migration.jsx" /* webpackChunkName: "component---src-pages-magento-to-shopify-migration-jsx" */),
  "component---src-pages-marsel-privacy-policy-jsx": () => import("./../../../src/pages/marsel-privacy-policy.jsx" /* webpackChunkName: "component---src-pages-marsel-privacy-policy-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-shopify-plus-agency-jsx": () => import("./../../../src/pages/shopify-plus-agency.jsx" /* webpackChunkName: "component---src-pages-shopify-plus-agency-jsx" */),
  "component---src-templates-agency-category-template-jsx": () => import("./../../../src/templates/AgencyCategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-agency-category-template-jsx" */),
  "component---src-templates-agency-city-template-jsx": () => import("./../../../src/templates/AgencyCityTemplate.jsx" /* webpackChunkName: "component---src-templates-agency-city-template-jsx" */),
  "component---src-templates-agency-country-template-jsx": () => import("./../../../src/templates/AgencyCountryTemplate.jsx" /* webpackChunkName: "component---src-templates-agency-country-template-jsx" */),
  "component---src-templates-marsel-jsx": () => import("./../../../src/templates/marsel.jsx" /* webpackChunkName: "component---src-templates-marsel-jsx" */),
  "component---src-templates-project-template-jsx": () => import("./../../../src/templates/ProjectTemplate.jsx" /* webpackChunkName: "component---src-templates-project-template-jsx" */)
}

